import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Id',
  },
  coordinatingOffice: {
    label: 'ICAO/IATA*',
    rules: 'required'
  },
  legalBusinessName: {
    label: 'Handler\'s legal business name',
  },
  vendorName: {
    label: 'Doing business as (D.B.A)',
  },
  managerName: {
    label: 'Manager name',
  },
  assitManagerName: {
    label: 'Assistant manager name',
  },
  opsPrimaryPhoneNo: {
    label: 'Operations Phone number',
  },
  opsSecondaryPhoneNo: {
    label: 'Operations secondary Phone number',
  },
  opsFaxNo: {
    label: 'Operations Fax number',
  },
  opsPrimaryEmail: {
    label: 'Operations Primary Email',
  },
  opsSecondaryEmail: {
    label: 'Operations Secondary Email',
  }
};
