// CustomDialog.tsx
import React, { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { observer } from 'mobx-react';
import { useStyles } from './CustomDialog.style';
import { PrimaryButton } from '@uvgo-shared/buttons';

type Props = {
  title: string;
  message: string;
  yesButton: string;
  noButton: string;
  onNoClick: () => void;
  onYesClick: () => void;
  className: string;
  isButtonVisible?: boolean;
};

const CustomDialog: FC<Props> = ({ 
  title, message, yesButton, noButton, onNoClick, onYesClick, className, isButtonVisible = true 
}) => {
  const classes = useStyles();

  const [ open, setOpen ] = React.useState(true);

  const handleClose = () => {
    onYesClick();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <div className={`${classes.dialog} ${className}`}>
        <WarningRoundedIcon className={classes.warningIcon} style={{ textAlign: 'center', display: 'inline-block' }} />

        <DialogTitle className={classes.title}>
          <strong>{title}</strong>
        </DialogTitle>
        <DialogContent className={classes.content}>{message}</DialogContent>
        <DialogActions>
          {
            isButtonVisible ?
              <Button onClick={onNoClick} className={classes.cancelButton} variant='text'>
                {noButton}
              </Button>
              : ''
          }
          <Box className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <PrimaryButton onClick={handleClose} autoFocus variant="contained">
              {yesButton}
            </PrimaryButton>
          </Box>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default observer(CustomDialog);
