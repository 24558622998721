import React, { FC, useEffect, useState } from 'react';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useBaseUpsertComponent } from '@uplink/shared';
import {
  IClasses,
  Utilities,
  IOptionValue,
  UIStore,
  IAPISearchFiltersDictionary,
  IAPIGridRequest,
} from '@wings-shared/core';
import { ConfirmNavigate, SidebarStore } from '@uplink-shared/layout';
import { Tooltip, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import {
  BaseStore,
  SlideOneAndTwoStore,
  SlideSevenStore,
  VendorLocationStore,
  VendorManagementStore,
} from '../../../../Stores';
import {
  LocationHoursModel,
  newVendorOnboarding,
  OperatingHoursAddData,
  VendorOnBoardSlideEightModel,
  VendorOnBoardSlideSevenModel,
  ViewInputControls,
} from '../../../Shared';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import InfoIcon from '@material-ui/icons/Info';
import { fields } from './Fields';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { forkJoin, of } from 'rxjs';
import { styles } from './Slide8.styles';

interface Props {
  classes?: IClasses;
  slideSevenStore: SlideSevenStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  vendorLocationStore: VendorLocationStore;
  vendorManagementStore: VendorManagementStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  onNextButtonDisable: (boolean) => void;
}

const Slide7: FC<Props> = ({
  classes,
  searchFilters,
  slideSevenStore,
  vendorLocationStore,
  vendorManagementStore,
  registerSaveData,
  activeStep,
  setActiveStep,
  onNextButtonDisable,
  slideOneAndTwoStore,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const [ locations, setLocations ] = useState([]);
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideEightModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {
    upsertSlideEight();
  };

  const validateForm = () => {
    return !formRef.isValid || formRef.hasError || !formRef.changed;
  };

  const loadLocationData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Vendor.Id',
          propertyValue: AuthStore.vendorProfile.id,
        },
      ]),
    };
    vendorLocationStore?.getVMSComparison(request).subscribe(response => {
      UIStore.setPageLoader(false);
      setLocations(response.results);
    });
  };

  useEffect(() => {
    onNextButtonDisable(validateForm());
  }, [ formRef.changed, formRef.isValid, formRef.hasError ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    loadInitialData();
    loadLocationData();
  }, [ activeStep ]);

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'coordinatingOffice',
            type: EDITOR_TYPES.DROPDOWN,
            options: locations,
            isHalfFlex: true,
          },
          {
            fieldKey: 'legalBusinessName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'vendorName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'managerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'assitManagerName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'opsPrimaryPhoneNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'opsSecondaryPhoneNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'opsFaxNo',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'opsPrimaryEmail',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'opsSecondaryEmail',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
        ],
      },
    ];
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertSlideEight = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideEightModel({
      ...useUpsert.form.values(),
      tempLocationId: slideOneAndTwoStore.tempLocationId,
    });
    slideSevenStore
      .upsertSlideEight(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideEightModel) => {
          useUpsert.form.reset();
          useUpsert.setFormValues(VendorOnBoardSlideEightModel.deserialize(response));
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'coordinatingOffice':
        const filteredLocations = vendorLocationStore.vendorLocationList.filter(data => {
          return (
            data.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.airportReference?.displayCode?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.vendorLocationCityReference?.cityReference?.name?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setLocations(filteredLocations);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideSevenStore
      .getByVendorIdSlideEight(AuthStore.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideEightModel[]) => {
        useUpsert.setFormValues(VendorOnBoardSlideEightModel.deserialize(response[0]));
      });
  };

  return (
    <ConfirmNavigate isBlocker={false}>
      <Typography className={classes.heading}>
        All communications for Handling and/or Fees are to be directed to:
      </Typography>
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={true}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
      </div>
    </ConfirmNavigate>
  );
};

export default inject(
  'slideSevenStore',
  'vendorLocationStore',
  'vendorManagementStore',
  'slideOneAndTwoStore'
)(withStyles(styles)(observer(Slide7)));
