import React, { FC, useEffect, useState } from 'react';
import { IClasses, UIStore } from '@wings-shared/core';
import { observer, inject } from 'mobx-react';
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InformationDialog from '../InformationDialog/InformationDialog';
import HoursData from './HoursData';
import { LocationHoursModel } from '../../Models/LocationHours.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { styles } from './OperatingHoursAddData.style';
import { VendorLocationHoursStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { EditIcon, EyeIcon } from '@uvgo-shared/icons';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import moment from 'moment';
import { RootDataStore } from '@uplink-shared/layout';

const dayNames = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
}

interface Props {
  classes?: IClasses;
  locationHoursList: LocationHoursModel[];
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  handleSave: () => void;
}

const OperatingHoursAddData: FC<Props> = ({ classes, locationHoursList, vendorLocationHoursStore, handleSave }) => {
  const [ timeData, setTimeData ] = useState<{ [key: string]: TimeRange[] }>({});
  const unsubscribe = useUnsubscribe();
  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};

    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            if (groupedTimeData[timeKey]) {
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: patternedRecurrenceDaysofWeekId,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: hoursId,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                hoursTypeId,
                statusId,
                accessLevelId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: scheduleId,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: patternedRecurrenceId,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };

  useEffect(() => {
    if (!locationHoursList) return;

    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    locationHoursList.forEach(item => {
      const { schedule, id: hoursId } = item;
      const { patternedRecurrence } = schedule;
      patternedRecurrence.patternedRecurrenceDaysofWeek.forEach(day => {
        const dayName = day.dayOfWeek.name;
        if (updatedTimeData[dayName]) {
          const startTime = moment(schedule.startTime).format('HH:mm');
          const endTime = moment(schedule.endTime).format('HH:mm');
          updatedTimeData[dayName].push({
            id: `original-${dayName}-${startTime}-${endTime}`,
            startTime,
            endTime,
            is24Hours: Boolean(
              moment(schedule.startTime).format('HH:mm') === '00:01' &&
                moment(schedule.endTime).format('HH:mm') === '23:59'
            ),
            hoursId,
            scheduleId: schedule.id,
            patternedRecurrenceId: patternedRecurrence.id,
            isNew: false,
            sequence: item.sequence,
            hoursTypeId: item.hoursType.id,
            statusId: item.status.id,
            accessLevelId: item.accessLevel.id,
            startDate: schedule.startDate,
            endDate: schedule.endDate,
            includeHoliday: schedule.includeHoliday,
            dayOfWeekId: dayOfWeekIds[dayName],
            patternedRecurrenceDaysofWeekId: day.id,
            active: true,
          });
        }
      });
    });

    setTimeData(updatedTimeData);
  }, [ locationHoursList ]);

  useEffect(() => {
    vendorLocationHoursStore.timeDataHoursData = groupDaysByTimeRange(timeData);
  }, [ timeData ]);

  const openPopupModel = () => {
    ModalStore.open(
      <InformationDialog
        dialogWrapperWidth={classes.dialogMainContainer}
        title="Please advise daily operating hours in local time (24 hours)"
        message={<HoursData timeData={timeData} locationHoursList={locationHoursList} />}
        isActionVisible={true}
        saveDataAction={() => handleSave()}
      />
    );
  };

  const viewTable = () => {
    ModalStore.open(
      <Dialog
        title="Operating Hours"
        open={true}
        onClose={() => ModalStore.close()}
        isPreventDrag
        dialogContent={() => {
          return (
            <TableContainer component={Paper} className={classes.tableView}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>Time Range</TableCell>
                    <TableCell>Applicable</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(timeData).map(day =>
                    timeData[day].map((timeRange, index) => (
                      <TableRow key={`${day}-${index}`}>
                        <TableCell component="th" scope="row">
                          {index === 0 && day}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`${timeRange.startTime} - ${timeRange.endTime}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {index === 0 && (
                            <Checkbox disabled checked={timeRange.is24Hours} style={{ marginRight: '10px' }} />
                          )}
                          {index === 0 && '24 Hours'}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
        dialogActions={() => null}
      />
    );
  };

  return (
    <Box className={classes.operatingHoursAddDataWraper}>
      <Box className="hourChips">
        <Box className="addDataWrapper">
          {dayNames.map(
            day =>
              timeData[day]?.length > 0 && (
                <div key={day}>
                  {timeData[day].map(timeRange => (
                    <label className="hoursDataChip" key={timeRange.id}>
                      <span>{`${day}; ${timeRange.startTime} - ${timeRange.endTime} HRS`}</span>
                    </label>
                  ))}
                </div>
              )
          )}
        </Box>
        <Box className="actionIcons">
          {locationHoursList.length > 0 && (
            <Tooltip title="View">
              <label className="eyeIcon" onClick={viewTable}>
                <EyeIcon />
              </label>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Box className={classes.defaultButton}>
        {locationHoursList.length === 0 ? (
          <PrimaryButton variant="outlined" color="primary" onClick={openPopupModel} startIcon={<AddBoxIcon />}>
            Add Operating Hours
          </PrimaryButton>
        ) : (
          <PrimaryButton variant="outlined" color="primary" onClick={openPopupModel} startIcon={<EditIcon />}>
            Edit Operating Hours
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
};

export default inject('vendorLocationHoursStore')(withStyles(styles)(observer(OperatingHoursAddData)));
