import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { IAPISearchFiltersDictionary, IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Button, Checkbox, FormControlLabel, Tab, Tabs, Typography, styled, withStyles } from '@material-ui/core';
import { SidebarMenuOperationalInfo } from '../../../Shared';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { styles } from './CustomNotes.styles';
import CustomNotesTab from './Components/CustomNotesTab/CustomNotesTab';
import { VendorLocationStore } from '../../../../Stores';
import AOEDesignation from './Components/AOEDesignation/AOEDesignation';
import GenDecRequirementTab from './Components/GenDecRequirementTab/GenDecRequirementTab';
import CustomHours from './Components/CustomHours/CustomHours';

interface Props {
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
  vendorLocationStore: VendorLocationStore;
}

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  width: '100%',
});

const CustomTab = styled(props => <Tab disableRipple {...props} />)(() => ({
  flex: 1,
  textTransform: 'none',
  padding: 0,
  color: '#7C7C7C',
  fontWeight: 600,
  fontSize: '14px',
  backgroundColor: '#D3D3D3',
  '&.Mui-selected': {
    color: '#202020',
    borderLeft: '4px solid #004BA0',
    background: '#FFFFFF',
  },
}));

interface Tab {
  label: string;
  value: string;
  component: React.ComponentType; // Assuming it's a React component
  desc: string;
  onNextButtonDisable: (state: boolean) => void;
  registerSaveData: (func: () => void) => void;
}


const CustomNotes: FC<Props> = ({ classes, vendorLocationStore }) => {
  const navigate = useNavigate();
  const [ activeTab, setActiveTab ] = useState('4');
  const [ checkedTabs, setCheckedTabs ] = useState([ false, false, false, true ]);
  const [ isNextDisabled, setIsNextDisabled ] = useState(true);
  const saveDataFuncRef = useRef({});

  useEffect(() => {
    if (!RootDataStore.locationData.airportDataManagement) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.airportDataManagement ]);

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
  }, []);

  const registerSaveData = (key, saveDataFunc) => {
    if (saveDataFunc) {
      saveDataFuncRef.current[key] = saveDataFunc;
    } else {
      delete saveDataFuncRef.current[key];
    }
  };

  const handleSaveClick = () => {
    const saveDataFunc = saveDataFuncRef.current[activeTab];
    if (saveDataFunc) {
      saveDataFunc();
    } else {
      console.warn('Save function not available for', activeTab);
    }
  };

  const handleNextButtonDisableState = (disabled: boolean) => {
    setIsNextDisabled(disabled);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setIsNextDisabled(true);
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckedTabs = [ ...checkedTabs ];
    newCheckedTabs[index] = checked;
    setCheckedTabs(newCheckedTabs);
  };

  const TABS:Tab[] = [
    {
      label: 'AOE Designation',
      value: '1',
      component: AOEDesignation,
      disabled: true,
      desc: 'AOE Designation',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('1', func),
    },
    {
      label: 'Gen Dec Requirement',
      value: '2',
      component: GenDecRequirementTab,
      disabled: true,
      desc: 'Gen Dec Requirement',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('2', func),
    },
    {
      label: 'Custom Hours',
      value: '3',
      component: CustomHours,
      disabled: true,
      desc: 'Custom Hours',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('3', func),
    },
    {
      label: 'Custom Notes',
      value: '4',
      component: CustomNotesTab,
      disabled: true,
      // desc: 'Custom Notes',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('4', func),
    },
  ];

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/locations')}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isNextDisabled}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  return (
    <div className={classes?.mainAirportHoursWrapper}>
      <ConfirmNavigate isBlocker={vendorLocationStore.isHoursValueChange}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes?.editorWrapperContainer}>
            <TabContext value={activeTab}>
              <CustomTabs value={activeTab} onChange={handleChange} variant="fullWidth">
                {TABS.map((tab, index) => (
                  <CustomTab key={tab.value} disabled={tab.disabled} label={tab.label} value={tab.value} />
                ))}
              </CustomTabs>
              {TABS.map((tab, index) => (
                <TabPanel key={tab.value} value={tab.value}>
                  {/* {index !== 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTabs[index]}
                          onChange={e => handleCheckboxChange(index, e.target.checked)}
                          color="primary"
                        />
                      }
                      className="checkbox-wrapper-header"
                      label={tab.desc}
                    />
                  )} */}
                  {(index === 0 || checkedTabs[index]) && tab.component && (
                    <Box>
                      <tab.component
                        onNextButtonDisable={handleNextButtonDisableState}
                        registerSaveData={func => registerSaveData(activeTab, func)}
                      />
                    </Box>
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </div>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(CustomNotes)));
